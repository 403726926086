<template>
  <button
    class="accordionButton"
    :class="{
      'accordionButton--active': isActive
    }"
    @click.prevent="$emit('event')"
  >
    <slot />
    <OsIcon name="Arrow" class="text-blue-600" />
  </button>
</template>
<script>
export default {
  props: {
    isActive: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  emits: ['event']
}
</script>

<style lang="scss">
.accordionButton {
  position: relative;
  font-family: $mb-font-text;
  font-size: rem(18);
  line-height: 1.13;
  letter-spacing: -0.2px;
  color: $blue;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  background: transparent;
  justify-content: center;
  align-items: center;
  -webkit-tap-highlight-color: transparent;

  svg {
    margin: rem(5);
    transition: 0.2s linear;
    width: 13px;
  }

  &--active {
    svg {
      transform: scaleY(-1);
    }
  }
}
</style>
